function initAnims() {
    var fadeInElems = gsap.utils.toArray(".fade.fadeIn");
    fadeInElems.forEach(function(elem) {
        gsap.from(elem, {
            autoAlpha: 0,
            ease: 'sine.out',
            duration: 2,
            scrollTrigger: {
                trigger: elem,
                start: "top 80%"
            }
        })
    })

    var fadeUpElems = gsap.utils.toArray(".fade.fadeUp");
    fadeUpElems.forEach(function(elem) {
        gsap.from(elem, {
            autoAlpha: 0,
            y: 15,
            ease: 'sine.out',
            duration: 2,
            scrollTrigger: {
                trigger: elem,
                start: "top 80%"
            }
        })
    })

    var fadeLeftElems = gsap.utils.toArray(".fade.fadeLeft");
    fadeLeftElems.forEach(function(elem) {
        gsap.from(elem, {
            autoAlpha: 0,
            x: -15,
            ease: 'sine.out',
            duration: 1,
            scrollTrigger: {
                trigger: elem,
                start: "top 80%"
            }
        })
    })

    var fadeRightElems = gsap.utils.toArray(".fade.fadeRight");
    fadeRightElems.forEach(function(elem) {
        gsap.from(elem, {
            autoAlpha: 0,
            x: 15,
            ease: 'sine.out',
            duration: 1,
            scrollTrigger: {
                trigger: elem,
                start: "top 80%"
            }
        })
    })
}